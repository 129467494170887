module.exports = [{
      plugin: require('/builds/kassellabs/help/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/kassellabs/help/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/kassellabs/help/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-116931857-6","head":false,"anonymize":true},
    },{
      plugin: require('/builds/kassellabs/help/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
