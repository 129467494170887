// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/builds/kassellabs/help/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/builds/kassellabs/help/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-breaking-bad-js": () => import("/builds/kassellabs/help/src/pages/breaking-bad.js" /* webpackChunkName: "component---src-pages-breaking-bad-js" */),
  "component---src-pages-game-of-thrones-js": () => import("/builds/kassellabs/help/src/pages/game-of-thrones.js" /* webpackChunkName: "component---src-pages-game-of-thrones-js" */),
  "component---src-pages-house-of-the-dragon-js": () => import("/builds/kassellabs/help/src/pages/house-of-the-dragon.js" /* webpackChunkName: "component---src-pages-house-of-the-dragon-js" */),
  "component---src-pages-index-js": () => import("/builds/kassellabs/help/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/builds/kassellabs/help/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-starwars-js": () => import("/builds/kassellabs/help/src/pages/starwars.js" /* webpackChunkName: "component---src-pages-starwars-js" */),
  "component---src-pages-strangerthings-js": () => import("/builds/kassellabs/help/src/pages/strangerthings.js" /* webpackChunkName: "component---src-pages-strangerthings-js" */),
  "component---src-pages-the-last-of-us-js": () => import("/builds/kassellabs/help/src/pages/the-last-of-us.js" /* webpackChunkName: "component---src-pages-the-last-of-us-js" */),
  "component---src-pages-westworld-js": () => import("/builds/kassellabs/help/src/pages/westworld.js" /* webpackChunkName: "component---src-pages-westworld-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/kassellabs/help/.cache/data.json")

